
    import {
        IonSegment, IonSegmentButton, IonHeader, IonTitle, IonLabel, IonToolbar, IonIcon, IonImg, IonItem
    } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import moment from 'moment';
    import { store } from '../../store/store';
    import { MemberApiClient, EventModel, PublicationModel } from '../../generated';
    import { downloadOutline, heartOutline, caretDownOutline, arrowForwardOutline, heart, ticketOutline } from 'ionicons/icons';
    import { LanguageMixin } from '@/mixins/LanguageMixin';

    export default defineComponent({
        name: 'Favorites',
        mixins: [LanguageMixin],
        components: {
            IonSegment,
            IonSegmentButton,
            IonHeader,
            IonTitle,
            IonLabel,
            IonToolbar,
            IonIcon,
            IonImg,
            IonItem,
        },
        data() {
            return {
                activeTab: localStorage.getItem("favoriteTab"),
                favoriteEvents: Array<EventModel>(),
                favoritePublicationModels: Array<PublicationModel>(),
                favoritePublications: localStorage.getItem("favoritePublications"),
                componentKey1: 0,
                componentKey2: 0,
                favoriteEventsLocal: localStorage.getItem("favoriteEvents")
            }
        },
        methods: {
            forceRerender(child: any) {
                this.componentKey1 += 1;
                this.componentKey2 += 1;
            },
            toggleExpand: function (e: number) {
                const expandHeader = document.querySelectorAll('[data-expand="expandfav-' + e + '"]')[0] as HTMLDivElement,
                    newHeight = expandHeader.nextElementSibling ? expandHeader.nextElementSibling.querySelector('.expandfav-content')?.clientHeight : null,
                    contentContainer = expandHeader.nextElementSibling as HTMLCanvasElement;

                expandHeader.classList.toggle('active');

                if (expandHeader.nextElementSibling && contentContainer.style.height === '') {
                    contentContainer.style.height = newHeight + 'px';
                } else {
                    contentContainer.style.height = '';
                }

                //closing other accordion items
                if (this.favoritePublicationModels) {

                    const nonClickedIndexes = this.favoritePublicationModels.filter((item) => item.id != e);

                    for (let i = 0; i < nonClickedIndexes.length; i++) {
                        if (nonClickedIndexes[i].id != e) {
                            const closeOtherHeaders = document.querySelectorAll('[data-expand="expandfav-' + nonClickedIndexes[i].id + '"]')[0],
                                contentContainerClosed = closeOtherHeaders.nextElementSibling as HTMLCanvasElement;

                            closeOtherHeaders.classList.remove('active');
                            contentContainerClosed.style.height = '';
                        }
                    }
                }
            },
            segmentChanged(ev: any) {
                this.activeTab = ev.detail.value;
                localStorage.setItem('favoriteTab', ev.detail.value)
            },
            loadData: function () {
                setTimeout(() => {
                    const client = new MemberApiClient();
                    client.getFavorites(store.state.currentLanguage).then(result => {
                        if (result != null) {
                            console.log(result);

                            if (result.eventDates) {
                                this.favoriteEvents = result.eventDates;
                            }

                            if (result.publications) {
                                this.favoritePublicationModels = result.publications;
                            }
                        }
                    }, error => {
                        console.log(error);
                    });
                }, 50);

            },
            date: function (date: string, item: string) {

                const formatDate = moment(date, 'MMM D YYYY').locale(store.state.currentLanguage != null ? store.state.currentLanguage.split('-')[0] : '');

                if (item === 'month') {
                    return formatDate.format('MMM');
                } else if (item === 'day') {
                    return formatDate.format('D');
                } else if (item === 'year') {
                    return formatDate.format('YYYY');
                } else {
                    return moment(date).format('MMM D YYYY');
                }
            },
            removeFavoriteEvent(event: EventModel) {
                const client = new MemberApiClient();
                client.removeFavorite(event.id);

                if (this.favoriteEventsLocal != undefined) {
                    const favArray = this.favoriteEventsLocal.split(",");

                    const index = favArray.indexOf(event.id.toString());
                    if (index > -1) {
                        favArray.splice(index, 1);
                    }

                    this.favoriteEventsLocal = favArray.join();
                    localStorage.setItem('favoriteEvents', this.favoriteEventsLocal);
                    this.loadData()
                }

                setTimeout(() => {
                    window.location.reload();
                }, 200);
            },
            removeFavoritePub(publication: PublicationModel) {
                const client = new MemberApiClient();
                client.removeFavoritePublication(publication.id);

                if (this.favoritePublications != undefined) {
                    const favArray = this.favoritePublications.split(",");

                    const index = favArray.indexOf(publication.id.toString());
                    if (index > -1) {
                        favArray.splice(index, 1);
                    }

                    this.favoritePublications = favArray.join();
                    localStorage.setItem('favoritePublications', this.favoritePublications);
                    this.loadData()
                }

                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
        },
        setup() {
            function getDateString(date: Date, format: string): string {
                return moment(date).format(format);
            }
            return {
                getDateString, downloadOutline, heartOutline, caretDownOutline, arrowForwardOutline, heart, ticketOutline
            }
        },
        ionViewDidEnter() {
            this.loadData();
            if (!localStorage.getItem('favoriteTab')) {
                this.activeTab = 'events';
            }
        }
    })
